import React, { useEffect } from "react";
import "./About.css";
import Navbar from "../../Components/Navbar/Navbar";

const About = () => {
  const handleScroll = () => {
    const sections = document.querySelectorAll(
      ".about, .indian, .host, .organizing, .past"
    );
    sections.forEach((section) => {
      const sectionTop = section.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (sectionTop < windowHeight - 100) {
        section.classList.add("show");
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger scroll animation on load
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="about">
        <h1>About the 52nd Immunocon</h1>
        <p>
        Considering the challenges faced by human society in the last three years due to the COVID-19 pandemic, it is of significant importance to look for a converging point where 'Immunology meets Technology.' Being a premier institute of engineering and technology and an Institute of National Importance in India, MNNIT Allahabad is hosting the 52nd Immunocon in 2025. Immunocon has never been organized in Prayagraj, one of the education hubs in India's north, particularly in Uttar Pradesh. Institutes like the University of Allahabad (a central university), the Indian Institute of Information Technology Allahabad (IIIT Allahabad), Motilal Nehru Medical College, and several others are located in Prayagraj city.

The 52nd Immunocon will provide a platform to bridge a gap between immunology and technology, which is a need of the hour. Using sustainable technology may offer quicker ways to surveillance and map various diseases. Besides, this provides tools to monitor various read-outs or end-points of any experiment effectively. In a developing country like India, we need sustainable techniques and quality indigenous instruments to enrich our research, which may lead to new avenues. As the country celebrated its 75th year of independence as Amrut Mahotsav, it is the duty of 'we immunologists' to join hands with the technologists to strengthen an eco-system of 'Immunotechnology' in the country.  

        </p>
        <div className="date">
          <h1>Date</h1>
          <p>November 20-22, 2025</p>
        </div>
        <div className="theme">
          <h1>Theme of the Conference</h1>
          <p>‘Immunology Meets Technology: Improving the Effectiveness and Reach of Immunotherapy’</p>
        </div>
        <button>
          <a href="#new-user" style={{ color: "inherit", textDecoration: "none" }}>
            New User
          </a>
        </button>
        <button>
          <a href="#already-registered" style={{ color: "inherit", textDecoration: "none" }}>
            Already Registered
          </a>
        </button>
      </div>

      <div className="indian">
        <h1>Indian Immunology Society (I.I.S.)
        </h1>
        <p>
        The Indian Immunology Society was established in 1972-73 to develop and expand the subject of immunology through training and research. The purpose is to use immunology to fight the major health challenges in India and promote opportunities for professional growth for researchers at all stages of their careers. Its members collaborate to advance knowledge, address shared interests, and facilitate the exchange of ideas and information amongst scientists working in different research areas of Immunology. These objectives are achieved through scientific meetings and interactions of the society.

The Indian Immunology Society is a group of professors, scientists, and researchers with professional training from all over the world dedicated to advancing immunology and its related disciplines, encouraging the sharing of knowledge and information between researchers, and addressing the potential application of immunologic principles in clinical practice. 
   </p>
        <button>
          <a href="https://indianimmunologysociety.org/Home" style={{ color: "inherit", textDecoration: "none" }}>
            For More Details
          </a>
        </button>
      </div>

      <div className="host">
        <h1>Host Institute: Motilal Nehru National Institute of Technology (MNNIT) Allahabad 
        </h1>
        <p>
        MNNIT-Allahabad, established in 1961-62 and recently celebrated its Diamond Jubilee, is one of the National Institutes of Technology and an Institute of National Importance in India. The Institute is situated in a holy city, Prayagraj. Prayagraj is a sacred city where three rivers, the 'Ganges,' 'Yamuna,' and the invisible 'Saraswati' (mythical) meet and continue their journey further. 

Motilal Nehru National Institute of Technology Allahabad is an Institute with a total commitment to quality and excellence in academic pursuits. It was established as one of the seventeen Regional Engineering Colleges of India in 1961 as a joint enterprise of the Government of India and Uttar Pradesh. It was an associated college of the University of Allahabad, India's third oldest university. The Institute began by offering Bachelor's Degree Programs in Civil, Electrical, and Mechanical Engineering. It was the first in the country to start an undergraduate program in Computer Science & Engineering in 1976-77. Subsequently, in 1982-83, undergraduate programs in Electronics Engineering and Production & Industrial Engineering were started. The first Master's Program of the Institute was introduced by the Mechanical Engineering Department in the year 1966. 

The Institute now offers B.Tech., M.Tech., Ph.D. Programs, MCA, MBA, M.Sc.
 </p>
        <button>
          <a href="https://www.mnnit.ac.in/]" style={{ color: "inherit", textDecoration: "none" }}>
            For More Details
          </a>
        </button>
      </div>

      <div className="organizing">
        <h1>Organizing Department</h1>
        <h3>Department of Biotechnology</h3>
        <p>
        Biotechnology at MNNIT Allahabad was established as a new academic unit under Applied Mechanics in 2006, aiming to integrate life sciences with engineering and develop cutting-edge technology through research, training, and technological innovation. The Independent Department of Biotechnology was established in April 2012. Since its inception, the department has witnessed a consistent rise in student's demand for the subject. Keeping a beat to the global demands for researchers in this field, a full-fledged postgraduate degree course (M. Tech) in Biotechnology was introduced in 2010. The department has also started a PhD program in biotechnology since 2009. 

The department is equipped with all the necessary instruments and several research facilities. The department has a young and enthusiastic faculty that is actively involved in research and teaching. Presently, the B. Tech, M. Tech, and PhD programs in biotechnology are being run with the involvement of fifteen faculty members. 

To find the details of faculty members, please click this link: https://www.mnnit.ac.in/index.php/department/engineering/biotech/biotechfp 

  </p>
        {/* <button>
          <a href="#organizing-details" style={{ color: "inherit", textDecoration: "none" }}>
            For More Details
          </a>
        </button> */}
      </div>

      <div className="past">
        <h1>Past Immunocon Conferences</h1>
        <p>
          Learn about the legacy of previous Immunocon conferences, showcasing the Indian 
          Immunology Society's efforts to advance research and collaboration.
        </p>
        {/* <button>
          <a href="#past-conferences" style={{ color: "inherit", textDecoration: "none" }}>
            View Past Conferences
          </a>
        </button> */}
      </div>
    </div>
  );
};

export default About;
