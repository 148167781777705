import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Banner from '../../Components/Banner/Banner'
import OurPartners from '../../Components/OurPartners/OurPartners'

import Content from '../../Components/Content/Content'

const Home = () => {
  return (
    <div className='home'>
      <Navbar/>
       <Banner/>
      <OurPartners/>
      {/* <FlipTimer />  */}
       <Content /> 
      
    </div>
  )
}

export default Home
