import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Organizing from './Pages/Organizing/Organizing';
import City from './Pages/City/City';

function App() {
  return (
    <div className='App' >
      <BrowserRouter>
      <Routes>
       
        <Route path ='/' element={<Home />}/>
        <Route path ='/aboutus' element={<About/>}/>
        <Route path ='/organising' element={<Organizing/>}/>
        <Route path ='/city' element={<City/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
