import React from "react";
import "./Content.css";
const Content = () => {
  return (
    <div clasName="content-container">
      <div className="c1">
        <h1>About the Conference</h1>
        <p>
          With growing complications and resistance to chemotherapy, modulating
          the immune system to mount a robust and targeted immune response is
          the need of the hour. New immunotherapeutic approaches are being
          developed with innovative thoughts. Strengthening the immune response
          and revoking immunosuppression are a few aspects of immune therapy.
          However, incorporating technological advancement in the
          immunotherapeutic approaches may offer a more sustainable, quicker,
          and affordable treatment for several diseases that are not easily
          contained. Early diagnosis, real-time monitoring, robust and effective
          immune response, and affordability to the masses are the needs of a
          developing and populous country like India.
        </p>
      </div>
      <div className="c1">
        
            <h1>Objectives of the Conference
</h1>
            <p>
            To encourage budding immunologists to take advantage of technology to develop novel immunotherapies and immunodiagnostics.
To propose an ecosystem where immunology plus technology can effectively deliver new modalities of therapy and diagnostics.
re the needs of a developing and
              populous country like India.
            </p>
        
        
      </div>
      <div className="c1">
        <h1>About the Institite and Place</h1>
        <p>
          MNNIT-Allahabad, established in 1961-62 and celebrated Diamond
          Jubilee, is one of the National Institutes of Technology and an
          Institute of National Importance in India. The Institute is situated
          in a holy city i.e., Prayagraj. Prayagraj is a sacred city where three
          rivers, the ‘Ganges,’ ‘Yamuna,’ and the invisible ‘Saraswati’
          (mythical) meet and continue their journey further. {" "}
        </p>
      </div>
      <div className="c1">
        <h1>About the Department</h1>
        <p>
          Biotechnology at MNNIT Allahabad was established as a new academic
          unit under Applied Mechanics in 2006 to integrate life sciences with
          engineering. An administratively independent Department of
          Biotechnology was established in April 2012. Since its inception, the
          department has witnessed a consistent rise in the students' demand for
          the subject. The department runs a full-fledged undergraduate
          (B.Tech.) and postgraduate degree course (M. Tech) in Biotechnology.
          The department has also started a PhD program in biotechnology since
          2009
        </p>
      </div>
    </div>
  );
};

export default Content;
