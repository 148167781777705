import React from "react";
import "./Organizing.css";
import Navbar from "../../Components/Navbar/Navbar";

const Organizing = () => {
  return (
    <div className="page-container">
      <Navbar />
      <div className="organizing-container">
        <h1 className="main-title">Organizing Committee</h1>

        {/* National Advisory Committee */}
        <section className="committee-section">
          <h2 className="section-title">National Advisory Committee</h2>
          <div className="card-container">
            {[
              { name: "Prof. Narinder K Mehra", role: "Chair" },
              { name: "Dr. Syamal Roy", role: "Member" },
              { name: "Dr. Dipankar Nandi", role: "Member" },
              { name: "Dr. Amit Awasthi", role: "Member" },
              { name: "Dr. Ambak K. Rai", role: "Member Secretary" },
            ].map((member, index) => (
              <div className="card" key={index}>
                <h5>{member.name}</h5>
                <p className="role">{member.role}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Scientific Program Advisory Committee */}
        <section className="committee-section">
          <h2 className="section-title">Scientific Program Advisory Committee</h2>
          <div className="scientific-committee">
            <div className="scientific-left">
              <div className="scientific-item">
                <h5>Dr. Dipankar Nandi</h5>
                <p>
                  Professor, Department of Biochemistry, Indian Institute of
                  Sciences, Bangalore
                </p>
              </div>
              <div className="scientific-item">
                <h5>Dr. Uma Kanga</h5>
                <p>
                  Additional Professor, Department of T.I.I., AIIMS New Delhi
                  and Vice President [North], Indian Immunology Society
                </p>
              </div>
              <div className="scientific-item">
                <h5>Dr. Ambak K. Rai</h5>
                <p>
                  Associate Professor, MNNIT Allahabad, Convenor [Immunocn-2025]
                </p>
              </div>
            </div>
            <div className="scientific-right">
              <p>Convenor [Immunocn-2024]</p>
              <p>Convenor [Immunocn-2023]</p>
              <p>Convenor [Immunocn-2025]</p>
            </div>
          </div>
        </section>

        {/* Organizing Committee */}
        <section className="committee-section">
          <h2 className="section-title">Organizing Committee</h2>
          <div className="details-grid">
            <div className="detail-item">
              <strong>Patron:</strong> Prof. R. S. Verma, Director, MNNIT
              Allahabad
            </div>
            <div className="detail-item">
              <strong>Chairperson:</strong> Head [Ex-Officio], Department of
              Biotechnology, MNNIT Allahabad
            </div>
            <div className="detail-item">
              <strong>Vice-chairperson:</strong> Prof. Shivesh Sharma, MNNIT
              Allahabad
            </div>
            <div className="detail-item">
              <strong>Convener/Organizing Secretary:</strong> Dr. Ambak Kumar
              Rai, MNNIT Allahabad
            </div>
            <div className="detail-item">
              <strong>Co-convener/Co-Organizing Secretary:</strong> Dr. Sameer
              Srivastava, MNNIT Allahabad
            </div>
            <div className="detail-item">
              <strong>Joint Organizing Secretaries:</strong>
              <ul>
                <li>Dr. Manisha Sachan, MNNIT Allahabad</li>
                <li>Dr. Seema Nara, MNNIT Allahabad</li>
                <li>Dr. Vishnu Agarwal, MNNIT Allahabad</li>
                <li>Dr. Rabbind Singh, University of Allahabad</li>
                <li>Dr. Ritu Mishra, University of Allahabad</li>
                <li>Dr. Abhishek Kumar Tiwari, MNNIT Allahabad</li>
              </ul>
            </div>
            <div className="detail-item">
              <strong>Treasurer:</strong> Dr. Abhishek Kumar Tiwari, MNNIT
              Allahabad
            </div>
          </div>
          <p className="note">
            Note: National Advisory Committee and Scientific Program Advisory
            Committee will be finalized later one.
          </p>
        </section>

        {/* Local Advisory Committee */}
        <section className="committee-section">
          <h2 className="section-title">Local Advisory Committee</h2>
        </section>
      </div>
    </div>
  );
};

export default Organizing;
