import React from "react";
import "./Navbar.css";
import immunocon_logo from "../../Assests/im_logo.jpg";
import nav_photo from "../../Assests/nav.png";
const Navbar = () => {
  return (
    <div>
      <div className="navbar">
        {/* <img src={nav_photo} alt="" /> */}
        <div className="navbar-top">
        <div className="navbar-logo">
          <img src={immunocon_logo} alt="Immunocon Logo" />
          <div className="conference-name">
            <h1>
              {" "}
              52<sup>th</sup> IMMUNOCON 2025
            </h1>
            <span className="location-tag">Allahabad</span>
          </div>
        </div>
        <div className="navbar-center">
          <h2>Indian Immunology Society</h2>
          <p>
            19<sup>th</sup> to 22<sup>th</sup> November, 2025 at MNNIT-A
          </p>
        </div>
        <div className="logo">
            <div className="mnnit-logo">
            <img src={immunocon_logo} alt="" />
            </div>
            <div className="immunology-logo">
            <img src={immunocon_logo} alt="" />
            </div>
        </div>


        </div>
       <div className="navbar-bottom"><div className="navbar-links">
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/organising">Organizing Committee</a>
            </li>
            <li>
              <a href="#program">Registration</a>
            </li>
            <li>
              <a href="#abstract">Accommodation</a>
            </li>
            <li>
              <a href="#registration">Program schedule</a>
            </li>
            <li>
              <a href="/city">About the city ‘Prayagraj’ </a>
            </li>
            <li>
              <a href="#accommodation">Sponsors
              </a>
            </li>
            <li>
              <a href="#sponsorship">Contact us
              </a>
            </li>
          </ul>
        </div></div>
        
      </div>
    </div>
  );
};

export default Navbar;
