import React from 'react';
import './Banner.css';

import immuno from "../../Assests/immun.gif";
const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-image">
        <img src={immuno} alt="Banner" />
        
        <div className="banner-overlay">
          <div className="banner-content">
            <h1>52<sup>nd</sup> IMMUNOCON 2025</h1>
            <p>19<sup>th</sup> to 22<sup>nd</sup> October, 2025 at MNNIT-A</p>
            <p>M.P. hall</p>
            <div className="content"> <p>The conference platform for scientists, researchers, and professionals of the Indian Immunology Society to exchange knowledge, collaborate on research, and promote advancements in the immunology field.</p>
            </div>
      <button className="banner-button">Register Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
