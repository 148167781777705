import React from 'react';
import "./City.css";
import Navbar from '../../Components/Navbar/Navbar';

const City = () => {
  return (
    <div>
        <Navbar/>
    <div className="city-container">
      <h1>About the City Prayagraj</h1>
      <section className="city-description">
        <p>
          Prayagraj, formerly Allahabad, has been a city of learning, wisdom, and writing. It is one of India's most vibrant politically,
          spiritually conscious, and spiritually awakened cities. Prayagraj is among the largest cities in Uttar Pradesh and is situated at
          the confluence of three rivers: Ganga, Yamuna, and the invisible Saraswati. The meeting point is known as Triveni or Sangam.
          The city, better known as "Prayag," has many governmental institutions, which include MNNIT Allahabad, High Court of Uttar Pradesh,
          Allahabad University, IIIT Allahabad, and tourist places like Sangam, Allahabad Fort, Anand Bhawan, Swaraj Bhawan, Narayani Ashram,
          Ashoka Pillar, Khusro Bagh, etc.
        </p>
        <p>
          At this holy city of Prayagraj, we invite you to a "Sangam" of Immunology and Technology at the "Immunocon-2025" at MNNIT, Allahabad.
        </p>
      </section>
      <section className="city-weather">
        <h2>Weather</h2>
        <p>
          During the end of November, the city experiences mild warm days but cool nights. The temperature during the middle/end of November
          usually varies between 20-28°C. Delegates are advised to bring light woolen clothes. The weather conditions will be updated as we
          approach the conference.
        </p>
      </section>
      <section className="city-attractions">
        <h2>Places to Visit in the City</h2>
        <p>
          The 'Kumbh Nagari' Prayagraj is a major tourist attraction in India worldwide. Major tourist attractions include Sangam, Allahabad Fort,
          Akshay Vat, Allahabad Museum, Anand Bhawan, Swaraj Bhawan, Chandrashekhar Azad Park (formerly Company Bagh), Allahabad Public Library,
          Ashoka Pillar, Khusro Bagh, Narayani Ashram. Additionally, the city is known for the Shakti Peeth Alopi Mata Mandir and Shringwerpur Dham.
        </p>
      </section>
      <section className="nearby-attractions">
        <h2>Nearby Cities to Visit</h2>
        <p>
          Other tourist attractions near Prayagraj include Bhakti Mandir in Kripalu Dham Mangarh Ashram (~50 km), Sheetala Mata Mandir or Kade Dham 
          (~70 km), Ayodhya (~150 km), Varanasi (~135 km), Sarnath (~150 km), Vindhyachal Temple (~100 km), and Chitrakoot (~120 km) within an 
          approximate radius of 150 km.
        </p>
      </section>
    </div>
    </div>
  );
};

export default City;
