import React from "react";
import "./OurPartners.css";
import immunocon_logo from "../../Assests/im_logo.jpg";

const OurPartners = () => {
  return (
    <div className="partner-container">
      <h1>Our Partners</h1>
      <div className="scrolling-wrapper">
        <div className="partner-box">
          <img src={immunocon_logo} alt="Partner Logo" />
          <img src={immunocon_logo} alt="Partner Logo" />
          <img src={immunocon_logo} alt="Partner Logo" />
          <img src={immunocon_logo} alt="Partner Logo" />
          <img src={immunocon_logo} alt="Partner Logo" />
        </div>
        {/* Duplicate content for seamless scrolling */}
        <div className="partner-box">
          <img src={immunocon_logo} alt="Partner Logo" />
          <img src={immunocon_logo} alt="Partner Logo" />
          <img src={immunocon_logo} alt="Partner Logo" />
          <img src={immunocon_logo} alt="Partner Logo" />
          <img src={immunocon_logo} alt="Partner Logo" />
        </div>
        
      </div>
    </div>
  );
};

export default OurPartners;
